<template>
  <div role="group" class="form-group form-row">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) }}</label>
    <div class="row no-gutters" :class="settings.classes.input">
      <div class="col-sm-8">
        <Multiselect ref="input" :class="{ 'is-invalid': !isValid }" v-model="state" v-bind="$attrs" v-on="listeners" :options="options" :placeholder="$t(placeholder || 'Select option')" :label="settings.label" :track-by="settings.trackBy" :showLabels="$attrs.showLabels || false" @keydown.tab.prevent="onTab($event)" @change="onSelect($event)" />
        <template v-if="!isValid">
          <div v-for="(error, index) in errors" :key="index" class="invalid-feedback">{{ error.message }}</div>
        </template>
      </div>
      <div class="col-sm-4 input-group">
        <button type="button" role="button" class="input-group-text" @click="$emit('addElement')"><CIcon name="cil-plus" /></button>
        <button v-if="state && settings.save" type="button" role="button" class="input-group-text bg-primary" @click="$emit('saveElement')"><CIcon name="cil-save" /></button>
        <button v-if="state && settings.delete" type="button" role="button" class="input-group-text bg-secondary" @click="$emit('removeElement')"><CIcon name="cil-x" /></button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

const AMSCONFIG = { trackBy: 'id', label: 'name', response: 'id', classes: { input: 'col-sm-9', label: 'col-sm-3' } }

// TODO: FIX TAB

export default {
  name: 'AMultiSelectMaster',
  inheritAttrs: false,
  components: {
    Multiselect
  },
  watch: {
    value(val) {
      //this.state = val
      this.state = this.options.find(option => option[this.settings.response] == val)
    }
    /*options(val) {
      console.log('OPTIONS', this.name, val)
      //this.parseSelected(this.value)
    }*/
    /*async value(val) {
      this.parseSelected(val)
      await this.$nextTick()
        this.$forceUpdate()
    },
    async prependId() {
      this.parseOptions()
    },
    async options() {
      this.parseSelected(this.value)
      await this.$nextTick()
        this.$forceUpdate()
    },
    async config() {
      this.parseSelected(this.value)
      await this.$nextTick()
        this.$forceUpdate()
    }*/
  },
  props: {
    value: {
      type: [String, Number, Object]
    },
    options: {
      type: Array, // TODO: Objects ?
      default: () => []
    },
    prependId: {
      type: Boolean
    },
    config: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    placeholder: {
      type: String
    },

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    },
    delay: Boolean
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model?.expression.split('.').pop()
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.emit('input', event),
        change: event => this.emit('change', event)
        //select: event => this.emit('select', event)
      }
    }
  },
  data() {
    return {
      settings: { ...AMSCONFIG, ...this.config },
      //selected: this.parseSelected(this.value)
      state: this.value // || undefined
    }
  },
  mounted() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
    //console.log(this.name)
  },
  methods: {
    emit(emit, event) {
      if (event && this.settings && this.settings.response) event.value = event[this.settings.response]
      this.$emit(emit, event ? event.value : null)
    },
    parseOptions() {
      const self = this
      self.options.forEach(option => {
        option.label = (self.prependId ? `(${(option.id < 10 ? '0' : '') + option.id}) ` : '') + option.name
      })
      if (self.prependId && self.settings) self.settings.label = 'label'
      //console.log(self.options)
    },
    /*parseSelected(value) {
      const self = this
      self.parseOptions()
      if (value && self.settings && self.settings.response && typeof value !== 'object') {
        value = self.options.find(option => option[self.settings.response] == value)
      }
      self.selected = value
      return value

      /*
      Different elements need different event types:

      element	event type	property
      <input[type="checkbox"]>	change	checked
      <input[type="radio"]>	change	checked
      <input>	input	value
      <textarea>	input	value
      <option>
      */
    //},

    // TODO: test it
    onSelect(e) {
      if (this.$attrs.multiple !== undefined) return
      const optionIndex = e.target.selectedOptions[0].dataset.key
      const option = this.options[optionIndex]
      const value = option.value !== undefined ? option.value : option
      this.state = value
      this.$emit('update:value', value, e)
    },
    onTab(e) {
      console.log('TABBB', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-text {
  height: calc(1.5em + 0.75rem + 2px);
}

.invalid-feedback {
  display: block;
}
</style>
